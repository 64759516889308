import gql from 'graphql-tag'
import { productDetailFields } from './productGetQuery'

const productUpdateQuery = gql`
  query productUpdate(
    $id: String!
    $active: Boolean!
    $productSku: String!
    $internalSku: String!
    $eshops: [String]!
    $expectedTurnover: String
    $expirationOffset: Number
    $localisation: [String]!
    $organisation: String!
    $name: String!
    $weight: String!
    $height: String!
    $width: String!
    $length: String!
    $referenceNumbers: Array!
    $packagingType: String!
    $productGroup: String
    $services: [String]
    $workAroundSnIn: Boolean
    $workAroundLot: Boolean
    $hsCode: String
    $countryOfOrigin: String
    $description: String
    $customsDescription: String
    $type: String
    $childrenProducts: Array
    $requiresAdditionalWork: Boolean
    $requiresQualitativeReceiving: Boolean
  ) {
    productUpdate(
      id: $id
      input: {
        active: $active
        productSku: $productSku
        internalSku: $internalSku
        eshops: $eshops
        expectedTurnover: $expectedTurnover
        expirationOffset: $expirationOffset
        eshops: $eshops
        localisation: $localisation
        services: $services
        organisation: $organisation
        name: $name
        weight: $weight
        height: $height
        width: $width
        length: $length
        referenceNumbers: $referenceNumbers
        packagingType: $packagingType
        productGroup: $productGroup
        workAroundSnIn: $workAroundSnIn
        workAroundLot: $workAroundLot
        hsCode: $hsCode
        countryOfOrigin: $countryOfOrigin
        description: $description
        customsDescription: $customsDescription
        type: $type
        childrenProducts: $childrenProducts
        requiresAdditionalWork: $requiresAdditionalWork
        requiresQualitativeReceiving: $requiresQualitativeReceiving
      }
    ) @rest(type: "Product", method: "PUT", path: "product/{args.id}") {
      ${productDetailFields}
    }
  }
`

export default productUpdateQuery
