import { ApolloClient } from '@apollo/client'
import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from './index'
import createFetchSaga from '@utils/store/createFetchSaga'
import organisationGetQuery from '@queries/organisationGetQuery'
import organisationsGetQuery from '@queries/organisationsGetQuery'
import organisationInfoGetQuery from '@queries/organisationInfoGetQuery'
import organisationJoinUsersToOrganisationQuery from '@queries/organisationJoinUsersToOrganisationQuery'
import organisationRemoveUsersFromOrganisationQuery from '@queries/organisationRemoveUsersFromOrganisationQuery'
import organisationUpdateQuery from '@queries/organisationUpdateQuery'
import pick from 'lodash/pick'

const organisationRequiredFields = [
  'id',
  'name',
  'vatNumber',
  'primaryCountry',
  'active',
  'automaticExchangeEnabled',
  'unbookPartiallyDeliveredExpeditions',
]

const fetchList = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({
      query: organisationsGetQuery,
      variables: {
        ...action.payload,
        select: [
          'id',
          'name',
          'owner',
          'addressLine1',
          'addressLine2',
          'addressLine3',
          'code',
          'primaryCountry',
          'ignoreAddressValidation',
          'active',
          'stockAdviceCloseInterval',
          'vatNumber',
          'registrationNumber',
        ],
        nested: true,
      },
    })
    .then(({ data }) => ({ data: data?.organisationsGet?.results }))
}

const fetchDetail = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return await client
    .query({ query: organisationGetQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.organisationGet }))
}

const fetchOrganisationInfo = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: organisationInfoGetQuery, variables: { registrationNumber: action.payload } })
    .then(({ data }) => ({ data: data?.organisationInfoGet }))
}

const fetchJoinUsersToOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: organisationJoinUsersToOrganisationQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.organisationJoinUsersToOrganisation }))
}

const fetchRemoveUsersFromOrganisation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  return client
    .query({ query: organisationRemoveUsersFromOrganisationQuery, variables: action.payload })
    .then(({ data }) => ({ data: data?.organisationRemoveUsersFromOrganisation }))
}

const fetchActivateValidation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: organisationGetQuery, variables: { id: action.payload.id } })
    .then(({ data }) => ({ data: data?.organisationGet }))

  const requiredData = pick(result.data, organisationRequiredFields)

  return client.query({
    query: organisationUpdateQuery,
    variables: { ...requiredData, ignoreAddressValidation: true },
  })
}

const fetchDeactivateValidation = async (client: ApolloClient<any>, action: Action): Promise<any> => {
  const result = await client
    .query({ query: organisationGetQuery, variables: { id: action.payload.id } })
    .then(({ data }) => ({ data: data?.organisationGet }))

  const requiredData = pick(result.data, organisationRequiredFields)

  return client.query({
    query: organisationUpdateQuery,
    variables: { ...requiredData, ignoreAddressValidation: false },
  })
}

export default function* watch(): Generator {
  if (typeof window === 'undefined') return // dont run on SSR
  yield takeLatest(actionTypes.run, createFetchSaga(actionTypes.run, fetchList))
  yield takeLatest(actionTypes.loadDetail.run, createFetchSaga(actionTypes.loadDetail.run, fetchDetail))
  yield takeLatest(actionTypes.aresLoad.run, createFetchSaga(actionTypes.aresLoad.run, fetchOrganisationInfo))
  yield takeLatest(
    actionTypes.joinUsersToOrganisation.run,
    createFetchSaga(actionTypes.joinUsersToOrganisation.run, fetchJoinUsersToOrganisation),
  )
  yield takeLatest(
    actionTypes.removeUsersFromOrganisation.run,
    createFetchSaga(actionTypes.removeUsersFromOrganisation.run, fetchRemoveUsersFromOrganisation),
  )
  yield takeLatest(
    actionTypes.activateValidation.run,
    createFetchSaga(actionTypes.activateValidation.run, fetchActivateValidation),
  )
  yield takeLatest(
    actionTypes.deactivateValidation.run,
    createFetchSaga(actionTypes.deactivateValidation.run, fetchDeactivateValidation),
  )
}
