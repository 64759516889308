import { takeLatest, takeEvery, put, delay, select } from 'redux-saga/effects'
import { actionTypes, actions } from './index'
import { displayToast } from '@utils/toast'
import { selectors as routerSelectors } from '@store/connectedRouter'
import { t } from '@lingui/macro'

function* apolloError(action: Action): Generator {
  const path = (yield select(routerSelectors.pathnameSelector)) as string
  // don't show error on login screen

  if (!/^\/user\/login/.test(path) || !/^\/admin\/login/.test(path)) {
    displayToast({
      type: 'error',
      title: t({ id: 'dashboard.message.error', message: 'Error occurred' }),
      text: action?.payload?.error || 'error',
    })
  }
}

function* onPush(action: Action): Generator {
  if (!action?.payload?.delay) return
  yield delay(action?.payload?.delay)
  yield put(actions.remove(action?.payload?.id))
}

export default function* watch(): Generator {
  yield takeLatest(actionTypes.apolloError, apolloError)
  yield takeEvery(actionTypes.push, onPush)
}
