import { Organisation } from '@typings/entities/Organisation'

type OrganizationListResult = {
  organisationsGet: {
    results: Organisation[]
  }
}

export const flatGridResult = (data: OrganizationListResult) => {
  const organisationsGet = data?.organisationsGet || {}
  const results = organisationsGet?.results || []

  return {
    data: {
      ...organisationsGet,
      results: results.map((organisation) => ({
        ...organisation,
        primaryCountryName: organisation.primaryCountry?.name,
        primaryCountry: organisation.primaryCountry?.code,
        owner: organisation.owner?.id ?? null,
        ownerFirstName: organisation.owner?.firstName,
        ownerLastName: organisation.owner?.lastName,
      })),
    },
  }
}
